var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { CollectionCaseService } from "~/services/domain-service/collection-case.service";
var dict = {
    todayFollow: { name: "今日未跟调", caseFiller: "TODAY_NO_FOLLOW" },
    tomorrowFollow: { name: "今日已跟调", caseFiller: "TODAY_FOLLOW" },
    ptpNumber: { name: "PTP", caseFiller: "PTP" },
    majorFollow: { name: "重点跟进", caseFiller: "MAJOR_FOLLOW" },
    oneToThreeNoFollow: { name: "1-3天未跟", caseFiller: "ONE_TO_THREE_NO_FOLLOW" },
    fourToSixNoFollow: { name: "4-6天未跟", caseFiller: "FOUR_TO_SIX_NO_FOLLOW" },
    threeDaysLeft: { name: "3天内退案", caseFiller: "THREE_DAYS_LEFT" },
    sevenDaysLeft: { name: "7天内退案", caseFiller: "SEVEN_DAYS_LEFT" },
};
var FilterCollection = /** @class */ (function (_super) {
    __extends(FilterCollection, _super);
    function FilterCollection() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.filterConditions = [
            { type: "primary", count: -1, text: "全部", caseFiller: "" },
            { type: "", count: 0, text: "今日未跟调", caseFiller: "TODAY_NO_FOLLOW" },
            { type: "", count: 1, text: "今日已跟调", caseFiller: "TODAY_FOLLOW" },
            { type: "", count: 2, text: "PTP", caseFiller: "PTP" },
            { type: "", count: 3, text: "重点跟进", caseFiller: "MAJOR_FOLLOW" },
            { type: "", count: 4, text: "1-3天未跟", caseFiller: "ONE_TO_THREE_NO_FOLLOW" },
            { type: "", count: 5, text: "4-6天未跟", caseFiller: "FOUR_TO_SIX_NO_FOLLOW" },
            { type: "", count: 6, text: "3天内退案", caseFiller: "THREE_DAYS_LEFT" },
            { type: "", count: 7, text: "7天内退案", caseFiller: "SEVEN_DAYS_LEFT" },
        ];
        return _this;
    }
    // @Watch('collectionCount', { immediate: true })
    // collectionCountChange(val) {
    //   this.filterConditions = [{ type: 'primary', count: -1, text: '全部', caseFiller: '' }]
    //   if (!val) return;
    //   for (let item in val) {
    //     this.filterConditions.push({
    //       type: '',
    //       count: val[item],
    //       text: dict[item].name,
    //       caseFiller: dict[item].caseFiller
    //     });
    //   }
    // }
    FilterCollection.prototype.onFilterSearch = function (item) {
        // 切换选项卡
        this.updateCurrentCondition(item);
    };
    FilterCollection.prototype.updateCurrentCondition = function (item) {
        // 切换按钮状态
        if (item.type !== "primary") {
            this.filterConditions.forEach(function (x) { return (x.type = ""); });
            item.type = "primary";
        }
    };
    FilterCollection.prototype.mounted = function () { };
    __decorate([
        Dependencies(CollectionCaseService)
    ], FilterCollection.prototype, "collectionCaseService", void 0);
    __decorate([
        Prop()
    ], FilterCollection.prototype, "collectionCount", void 0);
    __decorate([
        Emit("onFilterSearch")
    ], FilterCollection.prototype, "onFilterSearch", null);
    FilterCollection = __decorate([
        Component({})
    ], FilterCollection);
    return FilterCollection;
}(Vue));
export default FilterCollection;
